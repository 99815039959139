<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">LOAD SIGNALS</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(3)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>
          <div class="row h-75">
            <div class="col my-auto">
              <div class="row d-flex justify-content-center mt-100">

                      <div class="card">
                          <div class="card-header d-flex justify-content-center">
                              <h5>Upload files WAV</h5>
                          </div>
                          <div class="card-block">
                            <div v-if="job_id == ''">
                              <form action="#" class="dz-clickable d-flex justify-content-center">
                                  <input type="file" id="files" ref="files" @change="previewFiles" multiple>
                              </form>
                              <div class="text-center m-t-20">
                                  <button class="button-standard" @click="submitFiles">Upload</button>
                              </div>
                            </div>
                            <div v-else class="d-flex justify-content-center">
                              <p>✔️
                                Files uploaded successfully</p>
                            </div>
                          </div>
                      </div>

              </div>
            </div>

            <div class="col my-auto">
              <!-- <div class="input-label"><b>Muestreo Previo</b></div>
                <p v-if="signal_props.signal_nums == ''">Nº de Señales: </p>
                <p v-else>Nº de Señales:<b> {{ signal_props.signal_nums }}</b></p>
                <p v-if="signal_props.sampling == ''">Frecuencia de Muestreo: </p>
                <p v-else>Frecuencia de Muestreo:<b> {{ signal_props.sampling }} Hz</b></p>
                <p v-if="signal_props.resolution == ''">Resolución (bits): </p>
                <p v-else>Resolución (bits):<b> {{ signal_props.resolution }}</b></p>
                <p v-if="signal_props.duration == ''">Duración: </p>
                <p v-else>Duración:<b> {{ signal_props.duration }} s</b></p>
                <p v-if="signal_props.size == ''">Tamaño: </p>
                <p v-else>Tamaño:<b> {{ (parseFloat(signal_props.size) / 1048576).toFixed(2) }} MB</b></p>-->
                <h3 class="d-flex justify-content-center">Previous sampling</h3>
                <table class="table table-striped" style="max-width: 450px">
                  <tbody>
                    <tr>
                      <td>Nº of signals:</td>
                      <td>{{ signal_props.signal_nums }}</td>
                    </tr>
                    <tr>
                      <td>Sampling Rate:</td>
                      <td v-if="signal_props.sampling == ''"> </td>
                      <td v-else>{{ signal_props.sampling }} Hz</td>
                    </tr>
                    <tr>
                      <td>Resolution (bits):</td>
                      <td>{{ signal_props.resolution }}</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td v-if="signal_props.duration == ''"></td>
                      <td v-else>{{ signal_props.duration }} s</td>
                    </tr>
                    <tr>
                      <td>Size</td>
                      <td v-if="signal_props.size == ''"></td>
                      <td v-else>{{ (parseFloat(signal_props.size) / 1048576).toFixed(2) }} MB</td>
                    </tr>
                  </tbody>
                </table>
            </div> 
          </div>
          
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(5)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex"


// var wavFileInfo = require('wav-file-info');

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import { getData, postData, uploadFiles } from  '@/services/api'

export default {
  name: 'input4CargarSenales',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      job_id: 'dsptool/job_id',
      signal_props: 'dsptool/signal_props',
      signal_nums: 'dsptool/signal_nums',
      num_files_uploaded: 'dsptool/num_files_uploaded'
    }),
    // model: {
    //       set (val) { this.$store.dispatch('urn/change_model', val); },
    //       get () { return this.$store.state.urn.model }
    // },
  },
  data() {
    return {
      files: []
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    },
    previewFiles: function () {
      this.files = this.$refs.files.files
    },
    submitFiles: function () {
      if (this.files.length > 0) {
        let sizeOK = true
        this.files.forEach(file => {
          //console.log((file.size / 1048576).toFixed(2) + 'MB')
          if(file.size > 1073741824) {
            sizeOK = false
          }
        })
        if (sizeOK) {
          this.$store.dispatch('ui/change_status', 2)
          console.log(this.files)
          this.setFileNamesList()
          getData('processing/newjob/')
          .then( res => {
            this.$store.dispatch('dsptool/change_job_id', res.data.job_id)
            this.$store.dispatch('dsptool/change_signal_nums', this.files.length)
            this.uploadFiles(0)
          })
        }
        else {
          this.$toast.open({
            message: "El tamaño máximo de archivo es 1GB",
            type: "error",
            position: "top",
            pauseOnHover: true,
            duration: 6000,
          });
        }
      }
    },
    uploadFiles: function (index) {
      let formData = new FormData()
      formData.append('job_id', this.job_id)
      formData.append('file', this.files[index])
      uploadFiles('processing/uploadfiles/', formData)
      .then( res => {
        this.$store.dispatch('dsptool/change_num_files_uploaded', index+1)
        console.log("uploaded " + this.num_files_uploaded +  "/" + this.signal_nums)
        if (index < this.files.length -1) {
          this.uploadFiles(index+1)
        }
        else {
          this.finishedUpload()
        }
      })
    },
    finishedUpload: function () {
      let formData = new FormData()
      formData.append('job_id', this.job_id)
      postData('processing/getmetadata/', formData)
      .then(res => {
        this.$store.dispatch('dsptool/change_signal_props', res.data)
      })
      this.$store.dispatch('ui/change_status', 1)
    },
    setFileNamesList: function () {
      let fileNamesList = []
      this.files.forEach(file => {
        fileNamesList.push(file.name)
      })
      
      this.$store.dispatch('dsptool/change_fileNames', fileNamesList)
    }
  }
}
</script>

<style lang="scss" scoped>

/* body{
    background-color: whitesmoke
} */

.mt-100 {
    margin-top: 3rem;
}

.card {
    width: 400px;
    height: 350px;
    border-radius: 5px;
    border: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 2rem;
    background-color: #515151;
    color: $card-header-text;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 20px;
    position: relative
}

.card .card-header h5:after {
    content: "";
    /* background-color: #d2d2d2; */
    width: 101px;
    height: 1px;
    position: absolute;
    bottom: 6px;
    left: 20px;
    color: $card-header-text;
}

.card .card-block {
    padding: 2rem;
    /* background-color: whitesmoke; */
}

.dropzone.dz-clickable {
    cursor: pointer
}


.m-t-20 {
    margin-top: 20px
}

.btn-primary,
.sweet-alert button.confirm,
.wizard>.actions a {
    background-color: #4099ff;
    border-color: #4099ff;
    /* color: whitesmoke; */
    cursor: pointer;
    -webkit-transition: all ease-in .3s;
    transition: all ease-in .3s
}

/* .btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer
} */
</style>