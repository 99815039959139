<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">PROCESSING CONFIGURATION</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(5)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>
          <div class="form-col">
            <div class="row form-row">
                  <div class="form-check">
                      <b-form-checkbox
                        id="dft-check"
                        v-model="proces_dft"
                        name="dft-check"
                        value="true"
                        unchecked-value="false"
                      >
                        <b>DFT</b>
                      </b-form-checkbox>
                    </div>
            </div>
            <div class="separator" />
            <div class="row form-row">
                    <div class="form-check">
                      <b-form-checkbox
                        id="spl-check"
                        v-model="proces_spl"
                        name="spl-check"
                        value="true"
                        unchecked-value="false"
                      >
                        <b>SPL</b>
                      </b-form-checkbox>
                    </div>
                  <div style="margin-left: 3rem;">
                  <form>
                    <div class="row">
                      <div class="col">
                        <label for="freq_start">Initial Frequency</label><input type="text" class="form-control general-input" placeholder="Hz" v-model="proces_spl_data_freq_start">
                      </div>
                      <div class="col">
                        <label for="freq_end">Final Frequency</label><input type="text" class="form-control general-input" placeholder="Hz" v-model="proces_spl_data_freq_end">
                      </div>
                      <div class="col">
                        <label for="spl-octaves">Pass</label>
                        <select id="spl-octaves" class="form-control select-input" v-model="proces_spl_data_paso">
                          <option v-for="(paso, index) in paso_list" v-bind:key="index" :value="paso.id">{{ paso.nombre }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 1rem;">
                      <div class="col">
                        <label for="spl-filter">Filter Name</label>
                        <select id="spl-filter" class="form-control select-input" v-model="proces_spl_data_filtro">
                          <option v-for="(filtro, index) in filtro_list" v-bind:key="index" :value="filtro.id">{{ filtro.nombre }}</option>
                        </select>
                      </div>
                      <div class="col">
                        <label for="spl-filter-ord">Filter Order</label><input type="text" class="form-control general-input" placeholder="Order" v-model="proces_spl_data_orden">
                      </div>
                    </div>
                  </form>
                  </div>
            </div> 
            <div class="separator" />
            <div class="row form-row">
                  <div class="form-check">
                      <b-form-checkbox
                        id="d11-check"
                        v-model="proces_d11"
                        name="d11-check"
                        value="true"
                        unchecked-value="false"
                      >
                        <b>D11</b>
                      </b-form-checkbox>
                    </div>
                  <div style="margin-left: 3rem;">
                  <form>
                    <div class="row">
                      <div class="col">
                        <label for="d11-filter">Filter Name</label>
                        <select id="d11-filter" class="form-control select-input" v-model="proces_d11_data_filtro">
                          <option v-for="(filtro, index) in filtro_list" v-bind:key="index" :value="filtro.id">{{ filtro.nombre }}</option>
                        </select>
                      </div>
                      <div class="col">
                        <label for="d11-filter-ord">Filter Order</label><input type="text" class="form-control general-input" placeholder="Order" v-model="proces_d11_data_orden">
                      </div>
                    </div>
                  </form>
                  </div>
            </div>
            <div class="separator" />
            <div class="row form-row">
                  <div class="form-check">
                      <b-form-checkbox
                        id="espec-check"
                        v-model="proces_espec"
                        name="espec-check"
                        value="true"
                        unchecked-value="false"
                      >
                        <b>Spectrogram</b>
                      </b-form-checkbox>
                    </div>
            </div>
          </div>
          
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(7)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex"

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'input6Procesado',
  components: {
    navigation,
    BFormCheckbox
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      filtro_list: 'dsptool/filtro_list',
      paso_list: 'dsptool/paso_list',
    }),
    proces_dft: {
          set (data) { this.$store.dispatch('dsptool/change_proces_dft', data=="true"?true:false); },
          get () { return this.$store.state.dsptool.formInputs.proces_dft }
    },
    proces_spl: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl', data=="true"?true:false); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl }
    },
    proces_spl_data_freq_start: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl_data_freq_start', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl_data.freq_start }
    },
    proces_spl_data_freq_end: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl_data_freq_end', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl_data.freq_end }
    },
    proces_spl_data_paso: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl_data_paso', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl_data.paso }
    },
    proces_spl_data_filtro: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl_data_filtro', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl_data.filtro }
    },
    proces_spl_data_orden: {
          set (data) { this.$store.dispatch('dsptool/change_proces_spl_data_orden', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_spl_data.orden }
    },
    proces_d11: {
          set (data) { this.$store.dispatch('dsptool/change_proces_d11', data=="true"?true:false); },
          get () { return this.$store.state.dsptool.formInputs.proces_d11 }
    },
    proces_d11_data_filtro: {
          set (data) { this.$store.dispatch('dsptool/change_proces_d11_data_filtro', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_d11_data.filtro }
    },
    proces_d11_data_orden: {
          set (data) { this.$store.dispatch('dsptool/change_proces_d11_data_orden', data); },
          get () { return this.$store.state.dsptool.formInputs.proces_d11_data.orden }
    },
    proces_espec: {
          set (data) { this.$store.dispatch('dsptool/change_proces_espec', data=="true"?true:false); },
          get () { return this.$store.state.dsptool.formInputs.proces_espec }
    },
  },
  data() {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    }
  }
}
</script>

<style scoped>
.form-col{
  margin-top: 1rem;
}

.form-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-check{
  margin-left: 3rem;
}

.form-check-label{
  font-weight: bold;
}
</style>