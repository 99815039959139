export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour < 10) 
        hour = '0' + hour;
    if (minutes < 10)
        minutes = '0' + minutes;      

    let formatedDate = [day, month, year].join('-');
    let time = [hour, minutes].join(':');

    return [formatedDate, time].join(' ');
}